/* ============================================ *
 * Cookiebar
 * ============================================ */
 #cookie-bar {
   background: #fbfbfb;
   height: auto;
   line-height: 24px;
   color: #363636;
   text-align: center;
   padding-top: 1rem;
   padding-bottom: 1rem;
   -webkit-box-shadow: 0px 0px 5px 0px rgba(54, 54, 54, 0.15);
   -moz-box-shadow: 0px 0px 5px 0px rgba(54, 54, 54, 0.15);
   box-shadow: 0px 0px 5px 0px rgba(54, 54, 54, 0.15); }

 #cookie-bar.fixed {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%; }

 #cookie-bar.fixed.bottom {
   bottom: 0;
   top: auto;
   z-index: 9999; }

 #cookie-bar p {
   margin: 0;
   padding: 0; }

 #cookie-bar a {
   color: #ffffff;
   display: inline-block;
   border-radius: 4px;
   text-decoration: none;
   padding: 7.5px 15px;
   margin-left: 8px;
   -webkit-transition: 0.3s all;
   transition: 0.3s all; }

 #cookie-bar .cb-enable {
   background: #007700; }

 #cookie-bar .cb-enable:hover {
   background: #009900; }

 #cookie-bar .cb-disable {
   background: #990000; }

 #cookie-bar .cb-disable:hover {
   background: #bb0000; }

 #cookie-bar .cb-policy {
   background: #0033bb; }

 #cookie-bar .cb-policy:hover {
   background: #0055dd; }
