.element-padded {
  @include rem(padding-top, 60px);
  @include rem(padding-bottom, 60px);

  @include media-breakpoint-down(lg) {
    @include rem(padding-top, 45px);
    @include rem(padding-bottom, 45px);
  }

  @include media-breakpoint-down(md) {
    @include rem(padding-top, 30px);
    @include rem(padding-bottom, 30px);
  }
}

.element-bg {
  background-color: $theme-lighter-gray;
}

.element-bg-image {
  background-repeat: repeat-x;
  background-position: top left;
}
