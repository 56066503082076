.referentie--images {
  .element-gallery--image {
    border: 4px solid $white;
    border-radius: 6px;
    position: relative;
    margin: 0;

    &:before {
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      position: absolute;
      @include rem(font-size, 24px);
      color: $white;
      text-shadow: 0 0 3px rgba(0,0,0,0.4);
      content: "\f00e";
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
    }
  }
}

.gallery-video {

}

.referentie--videobutton {
  background: $white;
  border-radius: 6px;

  .fa {
    border-radius: 100%;
    @include rem(padding, 21px);
    @include rem(width, 80px);
    @include rem(height, 80px);
    @include rem(font-size, 24px);
    @include rem(line-height, 34px);
    text-align: center;
  }
}

.pswp__container {
  iframe {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}
