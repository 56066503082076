/* Header */
.header {
    background: $white;
    box-shadow: 0px 3px 12px transparentize($black, .84);
    position: relative;
}

.logo {
    float: left;
    color: #000;

    &:hover {
        text-decoration: none;
        color: #000;
    }
    img {
      @include rem(max-height, 75px);
    }
}

@media print {
    .logo {
        text-decoration: none !important;
    }
}
