/* Page */
.page-background {
    background-color: $wit;
}

.page {
    @include rem(padding, 30px 0 30px 0);
}

.reference--button {
    background a {
        color: #fff;
    }
}

.reference--tekstblock {
    background: #fff;
}
