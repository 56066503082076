/* Navigation styles */
.megamenu-navigation {
  @include media-breakpoint-up(lg) {
    position: relative;
    width: 100%;
    table-layout: fixed;
  }
  .navigation-item.has-children {
    .megamenu-btn {
      width: 100%;
      border: 0;
      background-color: transparent;
    }
    &.show {
      .megamenu-btn {
        color: $wit;
        background-color: $theme-medium-dark-gray;
      }
    }
    @include media-breakpoint-down(lg) {
      .megamenu-btn {
        display: none;
      }
    }
    @include media-breakpoint-up(lg) {
      .navigation-link {
        display: none;
      }
      .megamenu-btn {
        display: inline-block;
      }
    }
    @include media-breakpoint-down(sm) {
      .megamenu-btn {
        display: none;
      }
    }
  }
  .navigation-menu {
    @include media-breakpoint-up(lg) {
      width: 100%;
      .navigation-item {
        position: static;
        display: inline-block;
        text-align: center;
        @include rem(margin-left, 15px);
        &:hover {
          .navigation-submenu {
            visibility: hidden;
            opacity: 0;
          }
        }
      }
    }
  }
  .megamenu-dropdown {
    @include rem(max-width, 25px 15px 15px 15px);
    @include rem(padding, 30px 15px);
    top: 42px !important;
    width: 100%;
    transform: none !important;
    border: 0px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.2), 0 2px 3px rgba(0, 0, 0, 0.15);
  }
  .megamenu-menu {
    @include rem(margin-bottom, 20px);
    padding: 0;
  }
  .megamenu-title {
    @include rem(margin-bottom, 15px);
    @include rem(font-size, 26px);
    font-weight: bold;
    display: inline-block;
  }
  .megamenu-item,
  .megamenu-sublink-item {
    list-style: none;
  }
  .megamenu-link {
    font-weight: bold;
    color: #212529;
  }
  .megamenu-sublink-link {
    color: #212529;
  }
}

.justified-navigation {
  @include media-breakpoint-up(lg) {
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  .navigation-menu {
    @include media-breakpoint-up(lg) {
      width: 100%;
      .navigation-item {
        display: table-cell;
        text-align: center;
        width: 1%;
      }
    }
  }
}

.left-navigation {
  @include media-breakpoint-up(lg) {
    width: 100%;
  }
  .navigation-menu {
    @include media-breakpoint-up(lg) {
      width: 100%;
      .navigation-item {
        float: left;
      }
      .navigation-link {
        @include rem(padding, 10px 20px 10px 20px);
      }
    }
  }
}

/* Navigation toggle */
.navigation-toggle {
  float: right;
  border: none;
  color: $white;
  background-color: $theme-primary;
  border: 0px ;
  @include rem(padding, 4px 12px 4px 12px);
  @include media-breakpoint-up(lg) {
    display: none;
  }
  &:after {
    @include rem(font-size, 24px);
    position: relative;
    content: "\f0c9";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
  &:hover {
    color: $white;
    background-color: darken($theme-primary, 5%);
  }
  &:active,
  &:focus {
    outline: 0;

    &:focus {
      outline: 0;
    }
  }
}

/* Site Search */
.site-search-mobile-container {
  @include rem(padding, 20px 0 10px 0);
  @include media-breakpoint-down(xs) {
    @include rem(padding, 20px 15px 10px 15px);
  }
}
.site-search-mobile {
  @include rem(padding-right, 95px);
  position: relative;
  .site-search-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

/* Containers */
.navigation-container {
  @include media-breakpoint-down(lg) {
    @include rem(margin-top, 15px);
  }
  @include media-breakpoint-down(xs) {
    padding: 0;
  }
}
.navigation {
  width: 100%;
  @include media-breakpoint-up(lg) {
    display: inline-block !important;
  }
  @include media-breakpoint-down(sm) {
    @include rem(padding-bottom, 30px);
    border-top: 0;
  }
  @include media-breakpoint-down(xs) {
    padding-bottom: 0;
  }
  @include media-breakpoint-down(sm) {
    border-bottom: 1px solid #ccc;
  }
}

/* Menus */
.navigation-menu,
.navigation-submenu,
.navigation-sub-submenu {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navigation-submenu,
.navigation-sub-submenu {
  @include media-breakpoint-down(xs) {
    border-top: 1px solid #ccc;
  }
  @include media-breakpoint-up(lg) {
    @include rem(width, 220px);
    position: absolute;
    display: block !important;
    z-index: 99;
    left: -1px;
    transition: opacity 0.2s ease-out;
    border: 1px solid #ccc;
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
    background-color: #eee;
  }
}

/* List items */
.navigation-item,
.navigation-submenu-item,
.navigation-sub-submenu-item {
  @include media-breakpoint-down(xs) {
    border-top: 1px solid #ccc;
  }
  @include media-breakpoint-up(lg) {
    border-top: 1px solid #ccc;
  }
  position: relative;
  display: block;
  text-align: left;
  &:first-child {
    border-top: 0;
  }
}

.navigation-item {
  @include media-breakpoint-up(lg) {
    border-top: 0;
    &:hover {
      .navigation-submenu {
        @include rem(width, 220px);
        visibility: visible;
        opacity: 1;
        height: auto;
      }
    }
    &:last-child,
    &:nth-last-child(2) {
      &:hover {
        .navigation-submenu {
          @include rem(width, 220px);
          right: -1px;
          left: auto;
        }
      }
    }
  }
}

/* Links */
.navigation-item:first-child {
  .navigation-link {
    @include rem(margin-left, 0);
  }
}
.navigation-link {
  // @include rem(margin-left, 25px);
  @include rem(font-size, 18px);
  font-weight: 700;

  &.current {
    color: $theme-primary;
    border: solid 4px $theme-primary;
    @include rem(border-radius, 5px);
    @include rem(padding, 5px 15px);
    text-transform: uppercase;
  }

  &:focus {
    outline: 0;
  }

  &:active {
    &:focus {
      outline: 0;
    }
  }
}
.navigation-link,
.navigation-submenu-link,
.navigation-sub-submenu-link {
  @include rem(padding, 10px 12px);
  display: block;
  text-decoration: none;
  color: $theme-gray;
  &:hover,
  &:focus,
  &:visited
  &:link {
    text-decoration: none;
    color: $theme-primary;
  }
  @include media-breakpoint-down(xs) {
    @include rem(padding, 10px 15px 10px 15px);
  }
}

.navigation-submenu-link,
.navigation-sub-submenu-link {
  @include media-breakpoint-up(lg) {
    @include rem(padding, 8px 20px 8px 20px);
  }
}

.navigation-submenu-link {
  @include media-breakpoint-down(sm) {
    @include rem(padding-left, 35px);
  }
}
.navigation-sub-submenu-link {
  @include media-breakpoint-down(sm) {
    @include rem(padding-left, 65px);
  }
}

/* Dropdown chevrons */
.navigation-chevron {
  @include rem(padding, 10px 16px 10px 16px);
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  color: $white;
  background-color: $theme-medium-dark-gray;

  &:active,
  &:focus{
    outline: 0;
    &:focus{
      outline: 0;
    }
  }

  &:after {
    @include rem(font-size, 16px);
    content: "\f077";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
  &.collapsed {
    &:after {
      content: "\f078";
    }
  }
  @include media-breakpoint-down(xs) {
    border-left: 1px solid #ccc;
    color: $wit;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.navigation-item.has-children {
  .navigation-link {
    @include media-breakpoint-up(lg) {
      &:after {
        @include rem(font-size, 10px);
        position: relative;
        top: -2px;
        margin-left: 5px;
        content: "\f078";
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
      }
    }
  }
}

@media print {
  .navigation {
    display: none !important;
  }
}
