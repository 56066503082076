/* Footer */
.footer {
    background-color: $theme-primary;
    color: $wit;

    .footer--link,
    .footer--title {
        color: $wit;
    }

    .footer--social-icon {
        @include rem(font-size, 32px);
    }
    .footer--icon {
        @include rem(font-size, 13px);
    }
    .list-inline-item:not(:last-child) {
        @include rem(margin-right, 15px);
    }
    .link-list {
        .list-inline-item {
            display: block;
        }
    }
    .google-maps {
        min-height: 350px;
        height: 100%;
    }
}

.bottom-footer {
    background-color: darken($theme-primary, 5%);
    color: $gray-300;
    font-weight: 500;

    .bottom-footer--link {
        color: $wit;
    }

    .list-inline-item {
        &:not(:last-child):after {
            content: '/';
            @include rem(margin-left, 5px);
            opacity: 0.4;
        }
    }
}
