/* Breadcrumbs */
.breadcrumb {
  background-color: transparent;
  padding: 0;
  @include rem(margin-bottom, 20px);
  color: $theme-medium-gray;
  @include rem(font-size, 14px);
}

.breadcrumb-item {
  color: $theme-medium-gray;
  @include rem(font-size, 14px);

  a {
    color: $theme-medium-gray;
    @include rem(font-size, 14px);
  }
  
  &.active {
    color: $theme-medium-gray;
  }
}
