* {
    box-sizing: border-box;
}

html {
    background-color: $wit;
}

body {
    font-family: "PT Sans", Helvetica, Arial, sans-serif;
    font-display: fallback;
    font-weight: 500;
    height: 100%;
    color: $theme-gray;
    @include rem(font-size, 16px);
}

img {
    max-width: 100%;
}

strong {
    font-weight: 700;
}

.btn-theme-dark {
  color: $theme-secondary;
  &:hover {
    color: $theme-secondary;

    &:focus,
    &:active {
      color: $theme-secondary;

      &:focus{
        color: $theme-secondary;
      }
    }
  }
}

.google-maps {
    @include rem(padding, 30px);
    @include rem(border-radius, 36px);
    overflow: hidden;
    border: 0;

    @include media-breakpoint-down(md) {
        @include rem(border-radius, 6px);
        padding: 0;
    }
}

.collapsing {
    -webkit-transition: height 0.3s ease;
    -moz-transition: height 0.3s ease;
    -o-transition: height 0.3s ease;
    transition: height 0.3s ease;
}

.action {
    cursor: pointer;
}

.slick-slide {
    outline: 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.p-block-small {
    @include rem(padding, 15px 0);
}


.p-block-medium {
    @include rem(padding, 30px 0);

    @include media-breakpoint-down(lg) {
        @include rem(padding, 15px 0);
    }
}

.p-block {
    @include rem(padding, 45px 0);

    @include media-breakpoint-down(lg) {
        @include rem(padding, 30px 0);
    }

    @include media-breakpoint-down(md) {
        @include rem(padding, 15px 0);
    }
}

.sfeer-bg {
    background-size: cover;
    background-position: center right;
    position: relative;

    &:before {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        background: linear-gradient(to left, rgba(23, 23, 27, 0) 0%, #1483b7 75%);
    }
}

.image-wrap {
    display: inline-block;
    position: relative;

    &.gradient {
        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            content: "";
            background: linear-gradient(to bottom, rgba(64, 67, 84, 0) 0%, rgba(64, 67, 84, 0.65) 100%);
        }
    }
}
