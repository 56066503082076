.element-blocks {

    .element-blocks--link {
      transition: opacity .225s ease-in-out;

      &:hover {
        opacity: 0.9;
        text-decoration: none;
      }
    }

    .element-blocks--item {
        background: $theme-lighter-gray;
        color: $theme-gray;
        box-shadow: 0px 6px 12px transparentize($black, .84);
        height: 100%;
        overflow: hidden;
        border-radius: 6px;

        .element-blocks--image {
            width: 100%;
            &:after {
                content: " ";
                background: linear-gradient(120deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.10) 40%, rgba(0,0,0,0) 100%);
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            .lazyautosizes {
                width: 100%;
    
            }
        }
        .global-title {
            @include rem(font-size, 28px);
            color: $theme-gray;
            margin-bottom: 0;
        }
        .element-blocks--content {
            @include rem(padding, 0px 30px);
            color: $theme-gray;
        }
    }
}
