/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
    @include rem(font-size, 48px);
    font-family: $font-family-headings;
    color: $theme-gray;
    line-height: 1.1;

    @include media-breakpoint-down(xxl) {
        line-height: 54px;
    }

    @include media-breakpoint-down(xl) {
        line-height: 48px;
    }

    @include media-breakpoint-down(xl) {
      line-height: 42px;
    }
    @include media-breakpoint-down(md) {
      line-height: 34px;      
    }

    .regular-bold,
    strong {
        @include rem(font-size, 62px);
        font-weight: 700;

        @include media-breakpoint-down(xxl) {
            @include rem(font-size, 54px);
        }

        @include media-breakpoint-down(xl) {
            @include rem(font-size, 48px);
        }

        @include media-breakpoint-down(md) {
            @include rem(font-size, 38px);
        }
    }

    .regular-title,
    span {
        @include rem(font-size, 48px);
        font-weight: 500;

        @include media-breakpoint-down(xl) {
            @include rem(font-size, 38px);
        }

        @include media-breakpoint-down(md) {
            @include rem(font-size, 32px);
        }
    }
}

.global-title {
    @include rem(font-size, 62px);
    font-family: $font-family-headings;
    color: $theme-gray;
}

/*
h1 {
    @include rem(font-size, 32px);
}

h2 {
    @include rem(font-size, 28px);
}

h3 {
    @include rem(font-size, 24px);
}

h4 {
    @include rem(font-size, 21px);
}

h5 {
    @include rem(font-size, 18px);
}

h6 {
    @include rem(font-size, 16px);
}
*/
