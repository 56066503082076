/* Lists */
.material-icon-list {
  list-style: none;
  padding-left: 0;
  i {
    position: relative;
    top: 7px;
    right: 4px;
  }
}
