.element-video-banner {
  z-index: 0;
  position: relative;
  overflow: hidden;
  height: 85vh;

  .element-video-html {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  }

  .poster-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $wit;
  }

  .element-video-banner--content {
    position: relative;
    z-index: 5;
  }
}

.element-banner {

    .banner--slider {
      height: 70vh;
    }

    .element-banner--title {
        font-family: $font-family-headings;
        color: $white;
        line-height: 1.1;
        @include rem(font-size, 62px);
        font-weight: 700;
        text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
    }

    .element-banner--content {
        background: linear-gradient(120deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .element-banner--item {
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .slide-next {
        @include rem(font-size, 48px);
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 15px;
        z-index: 9999;
        color: $theme-light-gray;
        outline: none;
        box-shadow: none;

        @include media-breakpoint-down(md) {
          @include rem(font-size, 28px);
          border-radius: 0;
          right: 0;
          background: rgba(0,0,0,0.5);
        }
    }
    .slide-prev {
        @include rem(font-size, 48px);
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 15px;
        z-index: 9999;
        color: $theme-light-gray;
        outline: none;
        box-shadow: none;

        @include media-breakpoint-down(md) {
          @include rem(font-size, 28px);
          border-radius: 0;
          left: 0;
          background: rgba(0,0,0,0.5);
        }
    }
}
