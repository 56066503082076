/* Mixins */
@import "mixins/rem";

/* Base */
@import "base/variables";

/* Bootstrap */
@import "../../node_modules/bootstrap/scss/functions";
@import "bootstrap/fixes"; // SilverStripe Bootstrap fixes
@import "bootstrap/variables"; // Overide Bootstraps variables here
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";

/* Swiper Slider */
@import "../../node_modules/swiper/dist/css/swiper.css";

/* PhotoSwipe */
@import "../../node_modules/photoswipe/dist/photoswipe.css";
@import "../../node_modules/photoswipe/dist/default-skin/default-skin.css";

/* Global settings */
@import "base/global";

/* Typography */
@import "typography/buttons";
@import "typography/headings";
@import "typography/lists";
@import "typography/paragraphs";
@import "typography/tables";

/* Layout */
@import "layout/header";
@import "layout/footer";
@import "layout/product";
@import "layout/referentie";
@import "layout/page";

/* Pages */
@import "pages/category";
@import "pages/search-results";

/* Components */
@import "components/banner";
@import "components/usps";
@import "components/blocks";
@import "components/imagecontent";
@import "components/contactform";
@import "components/content";
@import "components/navbar";
@import "components/breadcrumbs";
@import "components/back-to-top";
@import "components/forms";
@import "components/lazysizes";
@import "components/cookiebar";
@import "components/elements";


/* Forms */
@import "forms/select";
@import "forms/search";
