.element-imagecontent {

    .element-imagecontent--image {
        @include rem(border-radius, 6px);
        overflow: hidden;

        &:after {
            content: " ";
            background: linear-gradient(120deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.10) 40%, rgba(0,0,0,0) 100%);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .lazyautosizes {

        }
    }

    .element-imagecontent--button {
        text-transform: uppercase;
        @include rem(font-size, 21px);
        background: $theme-primary;
        border-color: $theme-primary;
        color: $white;
        @include rem(padding, 7.5px 20px);
        i {
            @include rem(padding-right, 7.5px);
        }
    }
}
