.product-page {
  .product--images {
    .image-wrap {      
      border-radius: 6px;
      overflow: hidden;
    }
  }
}

.downloads--wrap {
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: $white;
  @include rem(padding, 30px)

  .downloads--header {
    @include rem(padding, 10px 15px)
    border-radius: 6px;
    background: $theme-lighter-gray;
    color: $theme-medium-gray;

    strong {
      color: $theme-medium-dark-gray;
    }
  }

  .downloads--download-row {
    @include rem(padding, 15px);
  }

  .downloads--hr {
    margin: 0;
    border-width: 4px;
    border-radius: 6px;
    border-color: $theme-lighter-gray;
  }
}
