/*

//Create your custom bootstrap button using the following example:
.btn-uitgelicht {
    @include button-variant($oranje, $oranje, darken($oranje,7.5%), darken($oranje,10%), darken($oranje,10%), darken($oranje,12.5%));
    @include rem(font-size,18px);
    @include rem(letter-spacing, .75px);
    font-weight:500;
    color: $wit;
    &:hover{
      color:$wit;
    }
}

*/