/* Space after paragraphs */
p + ul {

}
p + ol {

}

/* Space after lists */
ul + p {

}
ol + p {

}
