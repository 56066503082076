/* Merge Bootstrap and SilverStripe form styles */
div {
  .field {
    @extend .form-group;
  }
}
legend {
  font-size: 1rem;
}
.middleColumn {
  .text,
  .textarea {
    @extend .form-control;
  }
  .checkbox {
    @include rem(margin-right, 5px);
  }
  .radio {
    @include rem(margin-right, 5px);
  }
}
.Actions {
  .action {
    @extend .btn;
    @extend .btn-success;
  }
  #ForgotPassword {
    @include rem(margin-top, 20px);
  }
}
.optionset {
  list-style: none;
  padding-left: 0;
  margin: 0;
  .checkbox,
  .radio {
    display: inline;
    position: relative;
    top: 1px;
  }
}
.error.message {
  @extend .form-text;
}
.resetformaction {
  @extend .btn;
}
#popovers,
#tooltips {
  .btn {
    @include rem(margin-right, 5px);
    float: left;
  }
}
button {
  cursor: pointer;
}
#accordion {
  .card-header {
    cursor: pointer;
    padding-right: 40px;
    &:after {
      position: absolute;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: "\f077";
      top: 11px;
      right: 15px;
      font-size: 14px;
    }
    &.collapsed {
      &:after {
        content: "\f078";
      }
    }
  }
}
